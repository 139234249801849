import axios from 'axios/index'
import config from 'config'

export const GET_BRANDS = '[BRANDS] GET BRANDS'

const brandsProjection = {
  _id: 1,
  name: 1,
  slug: 1,
  logo: 1,
}

export function getBrands(newPage, newLimit, newSearchString) {
  const isNewPage = newPage !== null;
  const isNewLimit = newLimit !== null;
  const isNewSearchString = newSearchString !== null;

  return (dispatch, getState) => {
    const brands = getState().brands.brands
    const { page, limit, searchString } = brands
  
    const newParams = {
      page: isNewPage ? newPage : page,
      limit: isNewLimit ? newLimit : limit,
    } 

    if (isNewSearchString || searchString) {
      newParams.searchString = isNewSearchString ? newSearchString : searchString
    }
    
    const request = axios.get(`${config.endpoints.bff}/v2/brands`, {
      params: {
        ...newParams,
        projection: JSON.stringify(brandsProjection),
      }
    })
    return request.then((response) => {
      
      return dispatch({
        type: GET_BRANDS,
        brands: response.data.docs,
        count: response.data.totalDocs,
        payload: newParams,
      })}
    )
  }
}
