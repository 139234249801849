import * as Actions from '../actions'

const initialState = {
  data: [],
  searchString: "",
  page: 1,
  limit: 25,
  count: 0,
};

const brandsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_BRANDS: {

      return {
        ...state,
        ...action.payload,
        data: action.brands,
        count: action.count,
      };
    }

    default: {
      return state
    }
  }
}

export default brandsReducer
