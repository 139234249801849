import React from 'react'
import { FuseLoadable } from '@fuse'
import { authRoles } from 'auth'

export const FulfillmentAppConfig = {
  settings: {
    layout: {}
  },
  auth: authRoles.admin,
  routes: [
    {
      path: process.env.PUBLIC_URL + '/fulfillment',
      component: FuseLoadable({
        loader: () => import('./Fulfillment')
      })
    },
    {
      path: process.env.PUBLIC_URL + '/thirdpartylogistics/id/:idTpl/dispatchcost',
      component: FuseLoadable({
        loader: () => import('./ThirdPartyLogistic')
      })
    }
  ]
}