import { authRoles } from "auth";

export const fuseNavigationConfig = [
  {
    id: "menu",
    title: "Menú",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        icon: "dashboard",
        url: process.env.PUBLIC_URL + "/dashboard",
        auth: authRoles.distributor,
      },
      {
        id: "orders",
        title: "Administrar pedidos",
        type: "item",
        icon: "shopping_basket",
        url: process.env.PUBLIC_URL + "/orders",
        auth: authRoles.distributor,
      },
      /* {
        id: "carts",
        title: "Gestión de carros",
        type: "item",
        icon: "shopping_cart",
        url: process.env.PUBLIC_URL + "/carts",
        auth: authRoles.root,
      }, */
      /* {
        id: "distributions",
        title: "Distribución",
        type: "collapse",
        icon: "rv_hookup",
        auth: authRoles.user,
        children: [
          {
            id: "deliveries",
            title: "Despachos",
            type: "item",
            icon: "local_shipping",
            url: process.env.PUBLIC_URL + "/deliveries",
            auth: authRoles.user,
          },
          {
            id: "distributor",
            title: "Distribuidores",
            type: "item",
            icon: "people_outline",
            url: process.env.PUBLIC_URL + "/distributors",
            auth: authRoles.user,
          },
        ],
      }, */
      {
        id: "providers",
        title: "Proveedor",
        type: "collapse",
        icon: "business",
        auth: authRoles.user,
        children: [
          {
            id: "vendors",
            title: "Proveedores",
            type: "item",
            icon: "business",
            url: process.env.PUBLIC_URL + "/vendors",
            auth: authRoles.root,
          },
          {
            id: "coupons",
            title: "Blacklist cupones",
            type: "item",
            icon: "list",
            url: process.env.PUBLIC_URL + "/blacklist-coupons",
            auth: authRoles.root,
          },
          {
            id: "products",
            title: "Productos",
            type: "item",
            icon: "local_mall",
            url: process.env.PUBLIC_URL + "/products",
            auth: authRoles.user,
          },
          {
            id: "categories",
            title: "Categorías",
            type: "item",
            icon: "label",
            url: process.env.PUBLIC_URL + "/categories",
            auth: authRoles.root,
          },
          {
            id: "brands",
            title: "Marcas",
            type: "item",
            icon: "label",
            url: process.env.PUBLIC_URL + "/brands",
            auth: authRoles.root,
          },
        ],
      },
      {
        id: "fullfillment",
        title: "Fulfillment",
        type: "item",
        icon: "local_shipping",
        url: process.env.PUBLIC_URL + "/fulfillment",
        auth: authRoles.root,
      },
      {
        id: "coupons",
        title: "Cupones",
        type: "item",
        icon: "card_giftcard",
        url: process.env.PUBLIC_URL + "/coupons",
        auth: authRoles.root,
      },
      {
        id: "promotions",
        title: "Promociones",
        type: "item",
        icon: "photo_filter",
        url: process.env.PUBLIC_URL + "/promotions",
        auth: authRoles.root,
      },
      /* {
        id: "salesman",
        title: "Ejecutivos de venta",
        type: "item",
        icon: "supervised_user_circle",
        url: process.env.PUBLIC_URL + "/salesman",
        auth: authRoles.user,
      }, */
      {
        id: "notification",
        title: "Notificaciones",
        type: "item",
        icon: "notifications_active",
        url: process.env.PUBLIC_URL + "/notifications",
        auth: authRoles.root,
      },
      {
        id: "upload",
        title: "Cargar archivo",
        type: "item",
        icon: "cloud_upload",
        url: process.env.PUBLIC_URL + "/uploads",
        auth: authRoles.admin,
      },
      {
        id: "users-management",
        title: "Configuraciones",
        type: "collapse",
        icon: "settings",
        auth: authRoles.root,
        children: [
          {
            id: "users",
            title: "Usuarios",
            type: "item",
            icon: "person",
            url: process.env.PUBLIC_URL + "/users-management/users",
          },
          {
            id: "roles",
            title: "Roles",
            type: "item",
            icon: "person_pin",
            url: process.env.PUBLIC_URL + "/users-management/roles",
          },
          /* {
            id: "scopes",
            title: "Scopes",
            type: "item",
            icon: "security",
            url: process.env.PUBLIC_URL + "/users-management/scopes",
          }, */
          {
            id: "activities",
            title: "Actividades SII",
            type: "item",
            icon: "account_balance",
            url: process.env.PUBLIC_URL + "/users-management/activities",
          },
        ],
      },
    ],
  },
  {
    id: "session",
    title: "Sesión",
    type: "end",
    auth: authRoles.distributor,
    children: [
      {
        id: "products",
        title: "Cerrar sesión",
        type: "item",
        icon: "exit_to_app",
        url: process.env.PUBLIC_URL + "/logout",
      },
    ],
  },
];
